export function debounce(fn, delay = 300) {
  var timeoutID = null;

  return function() {
    clearTimeout(timeoutID);

    var args = arguments;
    var that = this;

    timeoutID = setTimeout(function() {
      fn.apply(that, args);
    }, delay);
  };
}
