import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("../layouts/Index"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/home/Home"),
      },
      {
        path: "/review",
        name: "review",
        component: () => import("../views/review/Review"),
      },
      {
        path: "/questions",
        name: "questions",
        component: () => import("../views/Questions"),
      },
      {
        path: "/about",
        name: "about",
        component: () => import("../views/About"),
      },
      {
        path: "/about-app",
        name: "about-app",
        component: () => import("../views/About-app"),
      },
      {
        path: "/conditions",
        name: "conditions",
        component: () => import("../views/Сonditions"),
      },
      {
        path: "/payment",
        name: "payment",
        component: () => import("../views/Payment"),
      },
      {
        path: "/contacts",
        name: "contacts",
        component: () => import("../views/Сontacts"),
      },
    ],
  },

  {
    path: "/favorites",
    component: () => import("../layouts/Index"),
    children: [
      {
        path: "",
        name: "favorite",
        component: () => import("../views/favorites/Index"),
      },
    ],
  },

  {
    path: "/login",
    component: () => import("../layouts/Index"),
    children: [
      {
        path: "",
        name: "login",
        component: () => import("../views/personal/Login"),
      }
    ]
  },

  {
    path: "/registration",
    component: () => import("../layouts/Index"),
    children: [
      {
        path: "",
        name: "registration",
        component: () => import("../views/registration/Index"),
      },
      {
        path: "/registration/registration-completed",
        name: "registration-completed",
        component: () => import("../views/registration/RegistrationCompleted"),
      }
    ]
  },

  {
    path: "/forgot-password",
    component: () => import("../layouts/Index"),
    children: [
      {
        path: "",
        name: "forgot-password",
        component: () => import("../views/forgot-password/Index"),
      },
      {
        path: "/forgot-password/confirm-code",
        name: "confirm-code",
        component: () => import("../views/forgot-password/ConfirmCode"),
      }
    ]
  },

  {
    path: "/vacancies",
    component: () => import("../layouts/Index"),
    children: [
      {
        path: "",
        name: "vacancies",
        component: () => import("../views/vacancies/Vacancies"),
      },
      {
        path: "resume",
        name: "resume",
        component: () => import("../views/vacancies/Resume"),
      },
    ],
  },

  {
    path: "/stocks",
    component: () => import("../layouts/Index"),
    children: [
      {
        path: "",
        name: "stocks",
        component: () => import("../views/stocks/Stocks"),
      },
      {
        path: ":code",
        name: "stock",
        component: () => import("../views/stocks/Stock"),
      },
    ],
  },

  {
    path: "/personal",
    component: () => import("../layouts/Index"),
    children: [
      {
        path: "",
        name: "personal",
        component: () => import("../views/personal/Personal"),
      },
      {
        path: "/personal/information",
        name: "personal-data",
        component: () => import("../views/personal/Personal-info"),
      },
      {
        path: "/personal/invoice",
        name: "personal-invoice",
        component: () => import("../views/personal/Account"),
      },
      {
        path: "/personal/bonus",
        name: "bonuses",
        component: () => import("../views/personal/Bonuses"),
      },
      {
        path: "/personal/change-password",
        name: "change-password",
        component: () => import("../views/personal/Change-password"),
      },
      {
        path: "/personal/orders",
        name: "orders",
        component: () => import("../views/personal/Orders"),
      },
      {
        path: "/personal/orders/:orderId",
        name: "current-order",
        component: () => import("../views/personal/Сurrent-order"),
      },
    ],
  },

  {
    path: "/catalog",
    component: () => import("../layouts/Index"),
    children: [
      {
        path: "/search",
        name: "search",
        component: () => import("../views/Search"),
        children: [
          {
            path: ":page/:query",
            name: "search",
            component: () => import("../views/Search"),
          },
        ],
      },
      {
        path: "/catalog/menu",
        name: "catalog-menu",
        component: () => import("../views/catalog/Catalog-menu"),
      },
      {
        path: "/catalog/menu/:category",
        name: "catalog-category",
        component: () => import("../views/catalog/Catalog-category"),
      },
      {
        path: ":category/:page",
        name: "catalog-list",
        component: () => import("../views/catalog/Catalog"),
      },
      {
        path: ":category/:page/filter",
        name: "catalog-filter",
        component: () => import("../views/catalog/Catalog-filter"),
      },
      {
        path: ":subsection/detail/:detail",
        name: "product",
        component: () => import("../views/catalog/Product"),
      },
    ],
  },

  {
    path: "/checkout",
    component: () => import("../layouts/Index"),
    children: [
      {
        path: "",
        name: "checkout",
        component: () => import("../views/checkout/Index"),
      },
      {
        path: "/checkout/ordering",
        name: "ordering",
        component: () => import("../views/checkout/Ordering"),
      },
      {
        path: "/checkout/ordering/success",
        name: "free",
        component: () => import("../views/checkout/Success"),
      },
    ],
  },

  {
    path: "/checkout-guest",
    component: () => import("../layouts/Index"),
    children: [
      {
        path: "",
        name: "checkout-guest",
        component: () => import("../views/checkout-guest/Index")
      }
    ]
  }
];



const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () {
    return {
      offset: { x: 0, y: 0 }
    }
  }
});

export default router;
