<template>
  <Preloader v-show="isPreloader" />
  <SvgContainer/>
  <RouterView />
  <SimpleModal />
</template>

<script>
import Preloader from "./components/preloader";
import SvgContainer from "./components/svg-container";
import SimpleModal from "./components/modals/simple-modal";

export default {
  name: "App",
  components: {
    SimpleModal,
    SvgContainer,
    Preloader,
  },

  computed: {
    isPreloader() {
      return this.$store.state.isPreloader;
    },
  },
};
</script>
