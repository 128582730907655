import { createStore } from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import auth from "./modules/auth";
import registration from "./modules/registration";
import forgotPassword from "./modules/forgot-password";
import home from "./modules/home";
import stocks from "./modules/stocks";
import vacancies from "./modules/vacancies";
import search from "./modules/search";
import personal from "./modules/personal";
import review from "./modules/review";
import catalog from "./modules/catalog";
import favorites from "./modules/favorites";
import checkout from "./modules/checkout";

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    auth,
    registration,
    forgotPassword,
    home,
    stocks,
    vacancies,
    search,
    personal,
    review,
    catalog,
    favorites,
    checkout,
  },
});
