import axios from '@/axios';

const state = {
  vacancies: {},
  resumeForm: [],
};

const mutations = {
  VACANCIES_SET_STATE(state, payload) {
    state[payload.key] = payload.data;
  },
};

const actions = {
  VACANCIES_FETCH_DATA({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get("/api/company/vacancy/")
        .then((response) => {
          commit("VACANCIES_SET_STATE", {
            key: "vacancies",
            data: response.data,
          });
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },
  VACANCIES_FETCH_RESUME_FORM({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get("/api/company/vacancy/resume/")
        .then((response) => {
          commit("VACANCIES_SET_STATE", {
            key: "resumeForm",
            data: response.data,
          });
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },
  VACANCIES_SEND_RESUME({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .post("/api/company/vacancy/resume/add/", data)
        .then((response) => {
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
