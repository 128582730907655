import axios from '@/axios';

const state = {
  products: [],
  pageCount: null,
  pageCurrent: null,
  isLoad: false,
  isLastPage: false,
};

const mutations = {
  SEARCH_SET_STATE(state, payload) {
    state[payload.key] = payload.data;
  },
  SEARCH_SET_PUSH_PRODUCTS(state, products) {
    state.products.push(...products);
  },
};

const actions = {
  SEARCH_FETCH_DATA({ commit }, data) {
    commit("LAUNCH_PRELOADER");
    commit("SEARCH_SET_STATE", {
      key: "isLoad",
      data: true,
    });

    const formdata = new FormData();

    formdata.append("QUERY", data.QUERY);
    formdata.append("PAGE", data.PAGE);

    return new Promise((resolve, reject) => {
      axios
        .post("/api/search/", formdata)
        .then((response) => {
          history.pushState(null, 'palladi', '/search/' + data.PAGE + '/' + data.QUERY);

          commit("SEARCH_SET_STATE", {
            key: "products",
            data: response.data['products']
          });

          commit("SEARCH_SET_STATE", {
            key: "pageCount",
            data: response.data['page_count'],
          });

          commit("SEARCH_SET_STATE", {
            key: "pageCurrent",
            data: response.data['page_current'],
          });

          commit("SEARCH_SET_STATE", {
            key: "isLastPage",
            data: false,
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
          commit("SEARCH_SET_STATE", {
            key: "isLoad",
            data: false,
          });
        });
    });
  },

  SEARCH_PAGINATION({ commit }, data) {
    commit("SEARCH_SET_STATE", {
      key: "isLoad",
      data: true,
    });

    const formdata = new FormData();

    formdata.append("QUERY", data.QUERY);
    formdata.append("PAGE", data.PAGE);

    return new Promise((resolve, reject) => {
      axios
        .post("/api/search/", formdata)
        .then((response) => {
          if (response.data['products'].length > 0) {
            history.pushState(null, 'palladi', '/search/' + data.PAGE + '/' + data.QUERY);

            commit("SEARCH_SET_PUSH_PRODUCTS", response.data['products']);

            commit("SEARCH_SET_STATE", {
              key: "pageCount",
              data: response.data['page_count'],
            });

            commit("SEARCH_SET_STATE", {
              key: "pageCurrent",
              data: response.data['page_current'],
            });

          } else {
            commit("SEARCH_SET_STATE", {
              key: "isLastPage",
              data: true,
            });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("SEARCH_SET_STATE", {
            key: "isLoad",
            data: false,
          });
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
