const mutations = {
  LAUNCH_PRELOADER(state) {
    state.isPreloader = true;
  },

  STOP_PRELOADER(state) {
    state.isPreloader = false;
  },

  SET_BASE_STATE(state, payload) {
    state[payload.key] = payload.data;
  },

  SET_MESSAGE_MODAL_DATA(state, payload) {
    state.messageModalSettings = {
      'error': payload.response.error,
      'message': payload.response.message
    };
  },
};

export default mutations;
