import axios from '@/axios';

const state = {
  reviewForm: [],
};

const mutations = {
  REVIEW_SET_STATE(state, payload) {
    state[payload.key] = payload.data;
  },
};

const actions = {
  REVIEW_SET_FORM({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get("/api/company/reviews/review/")
        .then((response) => {
          commit("REVIEW_SET_STATE", {
            key: "reviewForm",
            data: response.data,
          });
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },
  REVIEW_SEND_FORM({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();

    for (let key in data) {
      formdata.append(key, data[key]);
    }

    return new Promise((resolve, reject) => {
      axios
        .post("/api/company/reviews/review/add/", formdata)
        .then((response) => {
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },
  REVIEW_SEND_FORM_ABOUT_PRODUCT({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();

    for (let key in data) {
      formdata.append(key, data[key]);
    }

    return new Promise((resolve, reject) => {
      axios
        .post("/api/catalog/product-review-add/", formdata)
        .then((response) => {
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
