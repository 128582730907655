import axios from '@/axios';

const state = {
  stocks: [],
  selectStock: {},
};

const mutations = {
  STOCKS_SET_STATE(state, payload) {
    state[payload.key] = payload.data;
  },
};

const actions = {
  STOCKS_FETCH_DATA({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get("/api/stocks/")
        .then((response) => {
          commit("STOCKS_SET_STATE", {
            key: "stocks",
            data: response.data,
          });
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },

  STOCKS_FETCH_ITEM_DATA({ commit }, code) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();
    formdata.append("ELEMENT_CODE", code);

    return new Promise((resolve, reject) => {
      axios
        .post("/api/stocks/detail/", formdata)
        .then((response) => {
          commit("STOCKS_SET_STATE", {
            key: "selectStock",
            data: response.data,
          });
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
