import axios from '@/axios';

const state = {
  token: localStorage.getItem("user-token") || "",
  ordering: {},
  submiting: {},
  coupons: [],
  checkout: null,
  orderId: null,
  response: null,
};

const mutations = {
  CHECKOUT_SET_STATE(state, payload) {
    state[payload.key] = payload.data;
  },

  CHECKOUT_SET_STATE_PUSH(state, payload) {
    state.coupons.push(payload);
  },

  CHECKOUT_SET_ADDRESS(state, payload) {
    state.ordering.address = { ...state.ordering.address, value: payload }
  },
};

const actions = {
  CHECKOUT_FETCH_DATA({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .post('/api/basket/')
        .then((response) => {
          commit("SET_BASE_STATE", {
            key: "basket",
            data: response.data,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  CHECKOUT_GUEST_FETCH_DATA({ commit }) {
    commit("LAUNCH_PRELOADER");

    let formData = new FormData();

    if (this.state.basketGuest) {
      formData.append('BASKET', JSON.stringify(this.state.basketGuest));
    }

    return new Promise((resolve, reject) => {
      axios
        .post('/api/basket-guest/', formData)
        .then((response) => {
          commit("CHECKOUT_SET_STATE", {
            key: "checkout",
            data: response.data,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  CHECKOUT_SEND_PRODUCT_TO_CART({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();

    for (let key in data) {
      formdata.append(key, data[key]);
    }

    return new Promise((resolve, reject) => {
      axios
        .post("/api/basket/add/", formdata)
        .then((response) => {
          if (response.data.guest) {
            let basket, basketJson, product;

            if (this.state.basketGuest) {
              basket = this.state.basketGuest;
            } else {
              basket = [];
            }

            product = {
              'productId': parseInt(response.data.productId),
              'quantity': parseInt(response.data.quantity)
            };

            // Поиск существующего элемента и замена его на новое значение
            // todo из-за сравнения productId и id
            if (basket.find(item => item.productId === product.productId)) {
              basket.forEach(function (item, index) {
                if (product.productId === item.productId) {
                  let quantity = item.quantity + product.quantity;
                  let productNew = {
                    'productId': item.productId,
                    'quantity': quantity
                  };

                  this.splice(index, 1, productNew);
                }
              }, basket);
            } else {
              // Добавление нового элемента
              basket.push(product);
            }

            basketJson = JSON.stringify(basket);
            state.basketGuest = basketJson;
            localStorage.setItem("guest-basket", basketJson);
          } else {
            return new Promise((resolve, reject) => {
              axios
                .post('/api/basket/')
                .then((response) => {
                  commit("SET_BASE_STATE", {
                    key: "basket",
                    data: response.data,
                  });
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                })
                .then(() => {
                  commit("STOP_PRELOADER");
                });
            });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  CHECKOUT_REMOVE_PRODUCT_TO_CART({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();
    for (let key in data) {
      formdata.append(key, data[key]);
    }

    return new Promise((resolve, reject) => {
      axios
        .post("/api/basket/remove/", formdata)
        .then((response) => {

          if (response.data.guest) {
            let basket, basketJson, product;

            if (this.state.basketGuest) {
              basket = this.state.basketGuest;
            } else {
              basket = [];
            }

            product = {
              'productId': parseInt(response.data.productId),
              'quantity': parseInt(response.data.quantity)
            };

            // Поиск существующего элемента и замена его на новое значение
            if (basket.find(item => item.productId === product.productId)) {
              basket.forEach(function (item, index) {
                if (product.productId === item.productId) {
                  if (response.data.delete) {
                    this.splice(index, 1);
                  } else {
                    let quantity = item.quantity - product.quantity;

                    if (quantity > 0) {
                      let productNew = {
                        'productId': item.productId,
                        'quantity': quantity
                      };
                      this.splice(index, 1, productNew);
                    } else {
                      this.splice(index, 1);
                    }
                  }
                }
              }, basket);
            } else {
              commit("SET_BASE_STATE", {
                key: "basket",
                data: response.data.basket,
              });
            }

            basketJson = JSON.stringify(basket);
            state.basketGuest = basketJson;
            localStorage.setItem("guest-basket", basketJson);
          } else {
            return new Promise((resolve, reject) => {
              axios
                .post('/api/basket/')
                .then((response) => {
                  commit("SET_BASE_STATE", {
                    key: "basket",
                    data: response.data,
                  });
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                })
                .then(() => {
                  commit("STOP_PRELOADER");
                });
            });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  CHECKOUT_FETCH_ORDERING({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get("/api/order/")
        .then((response) => {
          commit("CHECKOUT_SET_STATE", {
            key: "ordering",
            data: response.data,
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  CHECKOUT_APPLY_COUPON({ commit }, coupon) {
    commit("LAUNCH_PRELOADER");
    commit("CHECKOUT_SET_STATE_PUSH", coupon.code);

    const formdata = new FormData();
    formdata.append("COUPONS", this.state.checkout.coupons);

    return new Promise((resolve, reject) => {
      axios
        .post("/api/order/", formdata)
        .then((response) => {
          commit("CHECKOUT_SET_STATE", {
            key: "ordering",
            data: response.data,
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  CHECKOUT_REMOVE_COUPON({commit}, coupon) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();
    let index = null;

    index = this.state.checkout.coupons.findIndex(item => item === coupon.code);

    if (index !== null)
      this.state.checkout.coupons.splice(index, 1);

    formdata.append("COUPONS", this.state.checkout.coupons);

    return new Promise((resolve, reject) => {
      axios
        .post("/api/order/", formdata)
        .then((response) => {
          commit("CHECKOUT_SET_STATE", {
            key: "ordering",
            data: response.data,
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  CHECKOUT_CONFIRM_ORDER({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();

    for (let key in data) {
      if (data[key] !== null) {
        formdata.append(key, data[key]);
      }
    }

    formdata.append("COUPONS", this.state.checkout.coupons);

    return new Promise((resolve, reject) => {
      axios
        .post("/api/order/confirm-order/", formdata)
        .then((response) => {
          commit("CHECKOUT_SET_STATE", {
            key: "orderId",
            data: response.data.confirm.orderId,
          });

          commit("CHECKOUT_SET_STATE", {
            key: "response",
            data: response.data.confirm.response,
          });

          commit("CHECKOUT_SET_STATE", {
            key: "detail",
            data: response.data.detail,
          });

          resolve(response);
        })
        .catch((error) => {
          commit("SET_MESSAGE_MODAL_DATA", error.response.data);

          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
