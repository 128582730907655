<template>
  <Modal v-if="message">
    <div class="modal__info">
      <div :class="error ? 'icon-wrap--error' : 'icon-wrap--success'" class="icon-wrap">
        <svg class="icon icon-cross" v-if="error">
          <use xlink:href="#cross"></use>
        </svg>
        <svg class="icon icon-check" v-else>
          <use xlink:href="#check"></use>
        </svg>
      </div>
      <div class="modal__text" v-html="message"></div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/modals/base-modal";

export default {
  components: {
    Modal,
  },

  computed: {
    message() {
      return this.$store.state.messageModalSettings.message;
    },

    error() {
      return this.$store.state.messageModalSettings.error;
    },
  },
};
</script>
