import axios from 'axios';

const state = {
  phone: '',
  confirmationCode: null
};

const mutations = {
  REGISTRATION_SET_STATE(state, payload) {
    state[payload.key] = payload.data;
  },
};

const actions = {
  GET_SMS_CODE({commit}, request) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();
    formdata.append("phone", request.phone);

    return new Promise((resolve) => {
      axios
        .post("/api/user/registration/sms-message-code/", formdata)
        .then((response) => {
          commit("REGISTRATION_SET_STATE", {
            key: "confirmationCode",
            data: response.data.code,
          });

          commit("REGISTRATION_SET_STATE", {
            key: "phone",
            data: request.phone,
          });

          resolve(response);
        })
        .catch((error) => {
          commit("SET_MESSAGE_MODAL_DATA", error.response.data);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  CONFIRM_CODE({commit}, request) {
    if (this.state.registration.confirmationCode !== request.code) {
      return new Promise(() => {
        commit("SET_MESSAGE_MODAL_DATA", {
          "response": {
            "error": true,
            "message": "Неверный код подтверждения. Попробуйте еще раз."
          }
        });
      });
    } else {
      commit("LAUNCH_PRELOADER");

      const formdata = new FormData();

      formdata.append("phone", this.state.registration.phone);

      return new Promise((resolve) => {
        axios
          .post("/api/user/registration/", formdata)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("SET_MESSAGE_MODAL_DATA", error.response.data);
          })
          .then(() => {
            commit("STOP_PRELOADER");
          });
      });
    }
  }
}

export default {
  state,
  mutations,
  actions
}
