import axios from '@/axios';

const actions = {
  FIRST_LOAD_INFORMATION({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get("/api/global-data/")
        .then((response) => {
          commit("SET_BASE_STATE", {
            key: "profile",
            data: response.data.profile,
          });
          commit("SET_BASE_STATE", {
            key: "basket",
            data: response.data.basket,
          });
          commit("SET_BASE_STATE", {
            key: "gift",
            data: response.data.gift,
          });
          commit("SET_BASE_STATE", {
            key: "redirects",
            data: response.data.redirects,
          });
          commit("SET_BASE_STATE", {
            key: "menu",
            data: response.data.menu,
          });
          commit("SET_BASE_STATE", {
            key: "contacts",
            data: response.data.contacts,
          });
          commit("SET_BASE_STATE", {
            key: "requisite",
            data: response.data.requisite,
          });
          commit("SET_BASE_STATE", {
            key: "deliveryPrice",
            data: response.data.deliveryPrice,
          });
          commit("SET_BASE_STATE", {
            key: "isLoaded",
            data: true,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },
};

export default actions;
