import axios from '@/axios';

const state = {
  isLoadedHome: false,
  advertisementBanner: [],
  catalogBanner: [],
  hit: [],
  innerBanner: [],
  mainBanner: [],
  novelties: [],
  offers: [],
  recommend: [],
  reviews: [],
  stocks: [],
  questions: [],
};

const mutations = {
  HOME_SET_STATE(state, payload) {
    state[payload.key] = payload.data;
  },
};

const actions = {
  HOME_FETCH_DATA({ commit, dispatch }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get("/api/home/global-information/")
        .then((response) => {
          console.log(response)
          if (!response.data) {
            dispatch("AUTH_LOGOUT").then(() => {
              window.location.reload();
            })
          }
          commit("HOME_SET_STATE", {
            key: "advertisementBanner",
            data: response.data.advertisementBanner,
          });
          commit("HOME_SET_STATE", {
            key: "catalogBanner",
            data: response.data.catalogBanner,
          });
          commit("HOME_SET_STATE", {
            key: "hit",
            data: response.data.hit,
          });
          commit("HOME_SET_STATE", {
            key: "innerBanner",
            data: response.data.innerBanner,
          });
          commit("HOME_SET_STATE", {
            key: "mainBanner",
            data: response.data.mainBanner,
          });
          commit("HOME_SET_STATE", {
            key: "novelties",
            data: response.data.novelties,
          });
          commit("HOME_SET_STATE", {
            key: "offers",
            data: response.data.offers,
          });
          commit("HOME_SET_STATE", {
            key: "recommend",
            data: response.data.recommend,
          });
          commit("HOME_SET_STATE", {
            key: "reviews",
            data: response.data.reviews,
          });
          commit("HOME_SET_STATE", {
            key: "stocks",
            data: response.data.stocks,
          });
          commit("HOME_SET_STATE", {
            key: "isLoadedHome",
            data: true,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },
  HOME_FETCH_INFO_FAQ({ commit }) {

    return new Promise((resolve, reject) => {
      axios
        .get("/api/info/faq/")
        .then((response) => {
          commit("HOME_SET_STATE", {
            key: "questions",
            data: response.data,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
