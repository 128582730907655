import axios from '@/axios';

const state = {
  favorites: [],
};

const mutations = {
  FAVORITES_SET_STATE(state, payload) {
    state[payload.key] = payload.data;
  },
};

const actions = {
  FAVORITES_FETCH_DATA_DEFAULT({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get(`/api/personal/favorites/`)
        .then((response) => {
          commit("FAVORITES_SET_STATE", {
            key: "favorites",
            data: response.data.items,
          });

          commit("FAVORITES_SET_STATE", {
            key: "favoriteList",
            data: response.data.favoriteList,
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  FAVORITES_FETCH_DATA_COLLECTION({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();
    formdata.append("collectionId", data.collectionId);

    return new Promise((resolve, reject) => {
      axios
        .post(`/api/personal/favorites/`, formdata)
        .then((response) => {
          commit("FAVORITES_SET_STATE", {
            key: "favorites",
            data: response.data.items,
          });

          commit("FAVORITES_SET_STATE", {
            key: "favoriteList",
            data: response.data.favoriteList,
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  FAVORITES_ADD_PRODUCT({ commit }, productId) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();
    formdata.append("PRODUCT_ID", productId);

    return new Promise((resolve, reject) => {
      axios
        .post(`/api/personal/favorites/change-collection/`, formdata)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  FAVORITES_ADD_LIST({commit}, data) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();
    formdata.append("listName", data.listName);

    return new Promise((resolve, reject) => {
      axios
        .post(`/api/personal/favorites/add-list/`, formdata)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  FAVORITES_REMOVE_LIST({commit}, data) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();
    formdata.append("collectionId", data.collectionId);

    return new Promise((resolve, reject) => {
      axios
        .post(`/api/personal/favorites/remove-list/`, formdata)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  FAVORITES_PRODUCT_MOVE_TO_LIST({commit}, data) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();
    formdata.append("products", data.products);
    formdata.append("collectionId", data.collectionId);
    formdata.append("collectionIdOld", data.collectionIdOld);

    return new Promise((resolve, reject) => {
      axios
        .post(`/api/personal/favorites/move-to-list/`, formdata)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  FAVORITES_ADD_PRODUCT_TO_BASKET({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();
    formdata.append('products', data['products']);

    return new Promise((resolve, reject) => {
      axios
        .post("/api/basket/add-products/", formdata)
        .then((response) => {
          new Promise((resolve, reject) => {
            axios
              .post('/api/basket/')
              .then((response) => {
                commit("SET_BASE_STATE", {
                  key: "basket",
                  data: response.data,
                });
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
