import "./sass/app.min.sass";

import { createApp } from "vue";

import axios from '@/axios';
import Maska from "maska";
import VLazyImage from "v-lazy-image";

import App from "./App.vue";
import store from "./store";
import router from "./router";
import VueYandexMetrika from 'vue-yandex-metrika';
import { debounce } from "@/utils/helpers";

const app = createApp(App);
app.config.devtools = true
app.use(store);
app.use(router);
app.use(Maska);
app.use(VueYandexMetrika, {
  id: 351889,
  router: router,
  options: {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true,
    ecommerce:"dataLayer",
  },
  debug: true,
  env: process.env.NODE_ENV
})
app.directive("debounce", (el, binding) => {
  if (binding.value !== binding.oldValue) {
    el.oninput = debounce(() => {
      el.dispatchEvent(new Event("change"));
    }, parseInt(binding.value) || 300);
  }
});

app.mount("#app");
app.component("SimpleModal", require("@/components/modals/simple-modal").default);
app.component("LazyPicture", VLazyImage);
axios.defaults.headers.common['Authorization'] = localStorage.getItem("user-token");
