import axios from 'axios';

const baseURL = '/';

const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

export default axios.create({
  baseURL,
})
