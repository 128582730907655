import axios from '@/axios';

const state = {
  catalog: [],
  products: [],
  innerBanner: [],
  filter: {},
  product: {},
  pagination: {},
  nameSection: null,
  isLoadedCatalog: false,
  isShowModalReview: false,
};

const mutations = {
  CATALOG_SET_STATE(state, payload) {
    state[payload.key] = payload.data;
  },
  CATALOG_SET_PUSH_PRODUCTS(state, products) {
    state.products.push(...products);
  },
};

const actions = {
  CATALOG_FETCH_DATA({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();

    formdata.append('category', data.category);

    return new Promise((resolve, reject) => {
      axios
        .post(`/api/catalog/get-menu/`, formdata)
        .then((response) => {
          commit("CATALOG_SET_STATE", {
            key: "catalog",
            data: response.data,
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  CATALOG_FETCH_BANNERS({ commit }) {
    // commit("LAUNCH_PRELOADER");
    state.innerBanner = [] // clearing banners on new catalog page

    return new Promise((resolve, reject) => {
      axios
        .get("/api/home/global-information/")
        .then((response) => {
          commit("CATALOG_SET_STATE", {
            key: "innerBanner",
            data: response.data.innerBanner,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          // commit("STOP_PRELOADER");
        });
    });
  },

  CATALOG_FETCH_PRODUCTS_LIST({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    let url = '';
    const formdata = new FormData();

    formdata.append('SECTION_CODE', data.category);
    formdata.append('PAGEN_1', data.page);

    if (data.query.SORT && data.query.ORDER)
      url += '?SORT=' + data.query.SORT + '&ORDER=' + data.query.ORDER;

    localStorage.setItem("subcategory", data.category);

    return new Promise((resolve, reject) => {
      axios
        .post(`/api/catalog/` + url, formdata)
        .then((response) => {
          commit("CATALOG_SET_STATE", {
            key: "nameSection",
            data: response.data.title,
          });

          commit("CATALOG_SET_STATE", {
            key: "filter",
            data: response.data.filter,
          });

          commit("CATALOG_SET_STATE", {
            key: "products",
            data: response.data.products,
          });

          commit("CATALOG_SET_STATE", {
            key: "pagination",
            data: response.data.pagination,
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  CATALOG_FILTRATION({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    let filterURL = '';
    let categoryURL = '?SECTION_CODE=' + data.category + '&PAGEN_1=' + data.page;

    if (data.query) {
      let first = true;
      for (const [key, value] of Object.entries(data.query)) {
        if (first === true)
          filterURL += key + '=' + value;
        else
          filterURL += '&' + key + '=' + value;

        first = false;
      }
    } else {
      data.filter.forEach((item, index) => {
        if (index === 0)
          filterURL += item.id + '=' + item.value;
        else
          filterURL += '&' + item.id + '=' + item.value;
      });

      filterURL += '&set_filter=Y';
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`/api/catalog/` + categoryURL + '&' + filterURL)
        .then((response) => {
          history.pushState(null, 'app-palladi', '/catalog/' + data.category + '/' + data.page + '/filter/' + '?' + filterURL);

          commit("CATALOG_SET_STATE", {
            key: "nameSection",
            data: response.data.title,
          });

          commit("CATALOG_SET_STATE", {
            key: "filter",
            data: response.data.filter,
          });

          commit("CATALOG_SET_STATE", {
            key: "products",
            data: response.data.products,
          });

          commit("CATALOG_SET_STATE", {
            key: "pagination",
            data: response.data.pagination,
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  CATALOG_PAGINATION({ commit }, data) {
    let url = '';
    let categoryURL = '?SECTION_CODE=' + data.category + '&PAGEN_1=' + data.page;

    if (data.sort.type && data.sort.order)
      url += 'SORT=' + data.sort.type + '&ORDER=' + data.sort.order;

    if (url !== '')
      url = '&' + url;

    return new Promise((resolve, reject) => {
      axios
        .post(`/api/catalog/` + categoryURL + url)
        .then((response) => {
          if (url !== '')
            history.pushState(null, 'palladi', '/catalog/' + data.category + '/' + data.page + '/?' + url);
          else
            history.pushState(null, 'palladi', '/catalog/' + data.category + '/' + data.page);

          commit("CATALOG_SET_STATE", {
            key: "pagination",
            data: response.data.pagination,
          });

          commit("CATALOG_SET_PUSH_PRODUCTS", response.data.products);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  CATALOG_SORT({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    let url = '';
    let categoryURL = '?SECTION_CODE=' + data.category + '&PAGEN_1=' + data.page;

    if (data.sort.type && data.sort.order)
      url += 'SORT=' + data.sort.type + '&ORDER=' + data.sort.order;

    return new Promise((resolve, reject) => {
      axios
        .post(`/api/catalog/` + categoryURL + '&' + url)
        .then((response) => {
          history.pushState(null, 'palladi', '/catalog/' + data.category + '/' + data.page + '/' + '?' + url);

          commit("CATALOG_SET_STATE", {
            key: "products",
            data: response.data.products,
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  CATALOG_FETCH_PRODUCT({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();

    for (let key in data) {
      formdata.append(key, data[key]);
    }

    return new Promise((resolve, reject) => {
      axios
        .post('/api/catalog/', formdata)
        .then((response) => {
          commit("CATALOG_SET_STATE", {
            key: "product",
            data: response.data,
          });
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
