import axios from '@/axios';

const state = {
  account: {},
  bonus: null,
  isSubscribe: false,

  orders: [],
  order: {},
};

const getters = {};

const mutations = {
  PERSONAL_SET_STATE(state, payload) {
    state[payload.key] = payload.data;
  },
};

const actions = {
  PERSONAL_UPDATE({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }

    return new Promise((resolve, reject) => {
      axios
        .post("/api/personal/information/", formData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  PERSONAL_SET_ACCOUNT({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get("/api/personal/account/")
        .then((response) => {
          commit("PERSONAL_SET_STATE", {
            key: "account",
            data: response.data,
          });
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },

  ///БОНУСЫ:

  PERSONAL_SET_BONUSES({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get("/api/personal/bonus/")
        .then((response) => {
          commit("PERSONAL_SET_STATE", {
            key: "bonus",
            data: response.data.bonus,
          });
          commit("PERSONAL_SET_STATE", {
            key: "isSubscribe",
            data: response.data.isSubscribe,
          });
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },

  PERSONAL_SUBSCRIBE_BONUSES({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get("/api/personal/bonus/?lgb_subscribe=Y")
        .then((response) => {
          commit("PERSONAL_SET_STATE", {
            key: "isSubscribe",
            data: response.data.isSubscribe,
          });
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },
  PERSONAL_UNSUBSCRIBE_BONUSES({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get("/api/personal/bonus/?lgb_unsubscribe=Y")
        .then((response) => {
          commit("PERSONAL_SET_STATE", {
            key: "isSubscribe",
            data: response.data.isSubscribe,
          });
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },

  /// ЗАКАЗЫ:

  PERSONAL_FETCH_ORDERS({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get("/api/personal/orders/")
        .then((response) => {
          commit("PERSONAL_SET_STATE", {
            key: "orders",
            data: response.data,
          });
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },

  PERSONAL_FETCH_ORDERS_HISTORY({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get("/api/personal/orders-history/?filter_history=Y/")
        .then((response) => {
          commit("PERSONAL_SET_STATE", {
            key: "orders",
            data: response.data,
          });
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },

  PERSONAL_FETCH_ORDER_DETAIL({ commit }, orderId) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();
    formdata.append("ID", orderId);

    return new Promise((resolve, reject) => {
      axios
        .post(`/api/personal/orders/detail/`, formdata)
        .then((response) => {
          commit("PERSONAL_SET_STATE", {
            key: "order",
            data: response.data,
          });
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
