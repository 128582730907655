import axios from 'axios';

const state = {
  phone: null,
  confirmationCode: null
};

const mutations = {
  FORGOT_PASSWORD_SET_STATE(state, payload) {
    state[payload.key] = payload.data;
  },
};

const actions = {
  FORGOT_PASSWORD_SMS_CODE({commit}, request) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();
    formdata.append("phone", request.phone);

    return new Promise((resolve, reject) => {

      axios
        .post("/api/user/password-restore/sms-message-code/", formdata)
        .then((response) => {
          commit("FORGOT_PASSWORD_SET_STATE", {
            key: "confirmationCode",
            data: response.data.code,
          });

          commit("FORGOT_PASSWORD_SET_STATE", {
            key: "phone",
            data: request.phone,
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },

  PASSWORD_RESTORE({commit}, request) {
    return new Promise((resolve, reject) => {
      if (this.state.forgotPassword.confirmationCode === request.code) {
        commit("LAUNCH_PRELOADER");

        const formdata = new FormData();
        formdata.append("phone", this.state.forgotPassword.phone);

        axios
          .post("/api/user/password-restore/", formdata)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .then(() => {
            commit("STOP_PRELOADER");
          });
      } else {
        reject({
          "response": {
            "error": true,
            "message": "Неверный код подтверждения. Попробуйте еще раз."
          }
        });
      }
    });
  }
}

export default {
  state,
  mutations,
  actions
}
