import axios from '@/axios';

const state = {
  profile: {},
  token: localStorage.getItem("user-token") || "",
  isAfterAuth: false,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
};

const mutations = {
  AUTH_SET_STATE(state, payload) {
    state[payload.key] = payload.data;
  },

  LOGOUT_CLEAR: (state) => {
    state.token = "";
    localStorage.removeItem("user-token");
    delete axios.defaults.headers.common.Authorization;
  },
};

const actions = {
  AUTH_LOGIN_REQUEST({ commit }, request) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();
    formdata.append("LOGIN", request.login);
    formdata.append("PASSWORD", request.password);
    formdata.append("BASKET_GUEST", localStorage.getItem("guest-basket"));

    return new Promise((resolve, reject) => {
      axios
        .post("/api/personal/authorization/", formdata)
        .then((response) => {
          const token = response.data.uuid;
          localStorage.setItem("user-token", token);
          axios.defaults.headers.common['Authorization'] = token
          localStorage.removeItem("guest-basket");

          commit("AUTH_SET_STATE", {
            key: "profile",
            data: response.data,
          });
          commit("AUTH_SET_STATE", {
            key: "token",
            data: token,
          });
          commit("SET_BASE_STATE", {
            key: "basketGuest",
            data: [],
          });
          resolve(response);
        })
        .catch((error) => {
          commit("LOGOUT_CLEAR");
          reject(error);
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },
  AUTH_LOGOUT({ commit }) {
    commit("LAUNCH_PRELOADER");

    return new Promise((resolve, reject) => {
      axios
        .get("/api/personal/logout/")
        .then((response) => {
          commit("LOGOUT_CLEAR");
          commit("STOP_PRELOADER");
          resolve(response);
        })
        .catch((error) => {
          commit("STOP_PRELOADER");
          commit("LOGOUT_CLEAR");
          reject(error);
        });
    });
  },

  AUTH_CHANGE_PASSWORD({ commit }, data) {
    commit("LAUNCH_PRELOADER");

    const formdata = new FormData();
    formdata.append("PASSWORD", data.password);
    formdata.append("CONFIRM_PASSWORD", data.confirm_password);

    return new Promise((resolve) => {
      axios
        .post("/api/personal/change-password/", formdata)
        .then((response) => {
          if (response.data.message) {
            commit("SET_MESSAGE_MODAL_DATA", {
              'response': {
                'error': true,
                'message': response.data.message
              },
            });
          } else {
            commit("SET_MESSAGE_MODAL_DATA", {
              'response': {
                'error': false,
                'message': 'Успешно'
              },
            });
          }

          resolve();
        })
        .then(() => {
          commit("STOP_PRELOADER");
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
