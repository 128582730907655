/**
 * todo перенести в модули
 */

const state = {
  profile: {},
  menu: {},
  contacts: {},
  requisite: {},
  deliveryPrice: {},
  basket: {},
  gift: {},
  redirects: {},
  isPreloader: false,
  isLoaded: false,
  basketGuest: localStorage.getItem("guest-basket") ? JSON.parse(localStorage.getItem("guest-basket")) : [],
  messageModalSettings: {
    'error': false,
    'message': ''
  },
};

export default state;
