<template>
  <div class="modal-mask">
    <div class="modal-wrap">
      <div class="modal modal--info animated">
        <button type="button" class="modal__close" @click="closeModal()">
          <svg class="icon icon-cross">
            <use xlink:href="#cross"></use>
          </svg>
        </button>
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$store.commit("SET_MESSAGE_MODAL_DATA", {
        'response': {
          'error': false,
          'message': '',
        }
      });
    }
  }
}
</script>
